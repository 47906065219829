<template>
  <div
    :class="cardClass"
    :draggable="canDrag"
    @dragend="onDragEnd"
    @dragstart="onDragStart"
    @dragover="onDragOver"
    @dragleave="onDragLeave"
    @dblclick="onSelect"
    @click.prevent.stop="onToggleMultiselect"
  >
    <button
      href="#"
      :class="[isSelected ? 'ring-2 ring-offset-2 ring-indigo-500' : 'focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500', 'cursor-pointer group block w-full aspect-w-10 aspect-h-7 rounded bg-gray-100 overflow-hidden']"
      @click="$emit('select', asset)"
    >
      <template v-if="asset.thumbnail">
        <img
          :src="asset.thumbnail"
          alt
          :class="[isSelected ? '' : 'group-hover:opacity-75', 'object-cover pointer-events-none']"
        >
      </template>

      <div
        v-else
        class="flex items-center justify-center object-cover w-full h-full"
        :class="iconColorClass"
      >
        <fa-icon
          :icon="itemIcon"
          :size="iconSizeGrid"
          :class="[iconClass, 'group-hover:opacity-75']"
        />
      </div>

      <span
        v-if="asset.comment_count > 0"
        class="absolute top-0 right-0 m-2"
      >
        <span
          class="rounded-full btn btn-xs btn-indigo px-1 py-0.5 border border-white"
        >{{ asset.comment_count }}</span>
      </span>

      <button
        type="button"
        class="absolute inset-0 focus:outline-none"
      >
        <span class="sr-only">View details for {{ asset.name }}</span>
      </button>
    </button>

    <div class="flex items-center justify-between">
      <p class="block mt-2 ml-0.5 text-sm font-medium text-gray-900 truncate pointer-events-none">
        <fa-icon
          icon="image"
          class="mr-0.5 text-gray-700"
          size="sm"
        />
        {{ asset.name }}
      </p>

      <div
        class="items-center"
        @click.stop
      >
        <AssetItemMenu
          :can-share="canShare"
          :can-delete="canDelete"
          :can-preview="canPreview && !isFolder"
          :can-replace="!isFolder"
          :can-move-item-up="canMoveItemUp"
          :parent-folder-id="activeFolder.parent"
          @rename-asset="onRename"
          @delete-asset="onDelete"
          @preview-asset="onPreview"
          @share-asset="onShare"
          @move-asset="onMoveDirect(activeFolder.parent)"
        />
      </div>
    </div>

    <!-- <div class="px-4 py-2 border-t border-gray-200 scene-info dark:border-gray-500"> -->
    <!-- Scene Name and Type -->
    <!-- <div class="flex items-center justify-between">
        <div class="mr-2 text-sm truncate">

          <div class="flex flex-col dark:text-gray-200">
            <span class="truncate">
              <span class="mr-1 fa-fw-" :class="iconTextColorClass">
                <fa-icon :icon="itemIcon"/>
              </span>
              {{ asset.name }}
            </span>

            <div v-if="dependsOn || showMeta" class="text-xs italic tracking-tight text-gray-500 dark:text-gray-400">
              <span class="mr-2" v-if="dependsOn"><fa-icon icon="link" class="fa-fw" size="sm"/> {{ dependsOnName }}</span>
              <span v-if="showMeta" class="capitalize" v-html="metaInfo"></span>
            </div>
          </div>
        </div>

        <div @click.stop>
          <AssetItemMenu
            v-bind:can-share="canShare"
            v-bind:can-delete="canDelete"
            v-bind:can-preview="canPreview && !isFolder"
            v-bind:can-replace="!isFolder"
            v-bind:can-move-item-up="canMoveItemUp"
            v-bind:parent-folder-id="activeFolder.parent"
            v-on:rename-asset="onRename"
            v-on:delete-asset="onDelete"
            v-on:preview-asset="onPreview"
            v-on:share-asset="onShare"
            v-on:move-asset="onMoveDirect(activeFolder.parent)"/>
        </div>

    </div>-->
    <!-- </div> -->
  </div>

  <!-- <p class="block text-sm font-medium text-gray-500 pointer-events-none">{{ file.size }}</p> -->
</template>
<script>
import item from './assets-explorer-collection-item-mixin.js'
import thumb from '../../asset-item-thumb-mixin.js'

export default {
  name: 'assets-explorer-collection-grid-item',
  mixins: [item, thumb],
  data() {
    return {
      tooltipVisible: false
    }
  },
  computed: {
    cardBackgroundImageStyle: function () {
      return `background-image: url('${this.thumbnail}'); background-size: cover; background-position: center;`
    },
    cardClass: function () {
      return {
        'border-blue-200 bg-blue-100 text-blue-600': this.isSelected || this.isFocused || this.isMultiselected
      }
    },
    thumbnail: function () {
      return this.asset.thumbnail
    },
    assetClass: function () {
      switch (this.asset.type) {
        case 'folder':
          return 'folder blue'
        case 'version200':
          return ''
        default:
          return this.asset.type + ' grey'
      }
    },
    showChildCount: function () {
      return this.isFolder
    },
    showMeta() {
      if (this.metaInfo !== null) return this.metaInfo
      return false
    },
    dependsOn() {
      return this.asset.depends_on_id !== null
    },
    dependsOnName() {
      return this.asset.depends_on_name
    },
    metaInfo() {
      if (this.asset.type === 'folder') {
        if (this.asset.child_count > 0) return this.asset.child_count + ' children'
        return 'Empty'
      }
      if (this.asset.status !== 'ready') {
        return this.asset.status
      }
      return null
    }
  },
  methods: {
    onClick() {
      if (this.tooltipVisible) return
      this.onToggleSelect()
    },
    onTooltipStateUpdate(state) {
      this.tooltipVisible = state
    }
  }
}
</script>
